<template>
  <OverviewActions type="tickets" :item-id="ticket.id" info take :disable-take="isTicketPending" @onTake="takeTicket" />
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

import OverviewActions from '@/components/UI/OverviewActions'

export default defineComponent({
  name: 'TicketboardRowActions',
  components: {
    OverviewActions,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    pendingTickets: {
      type: Array,
      default: _ => [],
    },
  },
  setup(props, { emit }) {
    const isTicketPending = computed(_ => props.pendingTickets.indexOf(props.ticket.id) !== -1)

    const takeTicket = _ => {
      emit('onTake')
    }

    return {
      isTicketPending,
      takeTicket,
    }
  },
})
</script>
