<template>
  <OverviewWrapper
    :value="selectedItem"
    :sidebar-items="[
      {
        title: 'Übersicht',
        icon: 'mdi-info',
        name: 'info',
        active: value => {
          return value && value.integration;
        },
      },
    ]"
    @input="onInput"
  >
    <AppCard :title="$t('Ticketboard')" title-actions>
      <template #titleActions>
        <div class="d-flex flex-column flex-md-row align-end align-md-center justify-end">
          <v-text-field
            v-model="search"
            class="mb-4 mb-md-0 mr-md-4"
            outlined
            hide-details="auto"
            dense
            type="text"
            label="Search Ticket"
            style="max-width: 200px"
            prepend-inner-icon="mdi-magnify"
          />
          <v-btn to="Tickets/create" color="secondary" rounded dark>
            <v-icon>mdi-plus</v-icon>
            <span class="d-none d-sm-inline">Ticket erstellen</span>
          </v-btn>
        </div>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="tickets"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending.getTicketboard"
        hide-default-footer
        :item-class="
          item => 'clickable' + (['closed', 'storno', 'canceled'].includes(item.status) ? highlightClasses : '')
        "
        @click:row="onClickRow"
      >
        <template #[`item.client`]="{ item }">
          <template v-if="item.handle">
            <b>{{ item.handle.organisation }}</b>
            {{ item.handle.firstname }}
            {{ item.handle.lastname }}
          </template>
        </template>

        <template #[`item.user`]="{ item }">
          <span v-if="item.user">
            {{ item.user.firstname }}
            {{ item.user.lastname }}
          </span>
        </template>

        <template #[`item.priority`]="{ item }">
          <v-chip x-small color="primary" class="mr-1">{{ item.priority }}</v-chip>
        </template>

        <template #[`item.duedate`]="{ item }">
          {{ item.duedate | moment('DD.MM.YYYY') }}
        </template>

        <template #[`item.actions`]="{ item }">
          <TicketboardRowActions :ticket="item" :pending-tickets="pendingTickets" @onTake="onTakeTicket(item.id)" />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>

    <template #[`sidebar.info`]="{ item }">
      <TicketSidebarInfo :item="item" />
    </template>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, reactive, ref, watch, computed, onBeforeUnmount } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import OverviewWrapper from '@/layouts/OverviewWrapper'
import TicketboardRowActions from '@/components/Ticketboard/TicketboardRowActions'
import TicketSidebarInfo from '@/components/Tickets/TicketSidebarInfo'

export default defineComponent({
  name: 'TicketBoardOverview',
  components: {
    AppCard,
    AppPagination,
    TicketboardRowActions,
    OverviewWrapper,
    TicketSidebarInfo,
  },
  setup(props, { root: { $store, $route, $vuetify }, root }) {
    const page = ref(1)
    const search = ref(null)
    const pendingTickets = reactive([])
    const headers = [
      {
        sortable: false,
        text: 'Ticket',
        value: 'ticket_unique_id',
      },
      {
        sortable: false,
        text: 'Client',
        value: 'client',
      },
      {
        sortable: false,
        text: 'Subject',
        value: 'subject',
      },
      {
        sortable: false,
        text: 'Priority',
        value: 'priority',
      },
      {
        sortable: false,
        text: 'Due date',
        value: 'duedate',
      },
      {
        sortable: false,
        text: 'Status',
        value: 'status',
      },
      {
        sortable: false,
        text: 'User',
        value: 'user',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const selectedItem = ref(null)
    const selectedRow = ref(null)

    const activeQuery = computed(_ => root.$route.query)

    // store
    const tickets = computed(_ => $store.state.tickets.tickets)
    const pagination = computed(_ => $store.state.tickets.pagination)
    const pending = computed(_ => $store.state.tickets.pending)
    const highlightClasses = computed(_ => {
      return $vuetify.theme.dark ? 'grey darken-4 text--disabled' : 'grey lighten-4 text--disabled'
    })
    const getTicketboard = payload => $store.dispatch('tickets/getTicketboard', payload)
    const takeTicket = ticketId => $store.dispatch('tickets/takeTicket', ticketId)
    const clearTickets = _ => $store.commit('tickets/CLEAR_TICKETS')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getTicketboard({ page: page.value })

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      clearTimeout(delayTimer)
      getTicketsWithParams()
    })

    const delayTimer = ref(null)
    watch(search, _ => {
      clearTimeout(delayTimer.value)
      delayTimer.value = setTimeout(() => {
        page.value = 1
        getTicketsWithParams()
      }, 500)
    })

    const getTicketsWithParams = _ => {
      const params = {
        page: page.value,
      }

      if (search.value) {
        params.search = search.value
      }

      getTicketboard(params)
    }

    const onTakeTicket = id => {
      const pendingButtonIndex = pendingTickets.indexOf(id)
      if (pendingButtonIndex !== -1) {
        return
      }

      if (confirm('Sicher, dass Sie das Ticket nehmen wollen?')) {
        pendingTickets.push(id)

        takeTicket(id)
          .then(async _ => {
            addToast({
              msg: 'Ticket erfolgreich genommen',
              type: 'success',
            })

            await getTicketsWithParams()
            pendingTickets.splice(pendingButtonIndex, 1)
          })
          .catch(error => {
            addToast({
              msg: 'Ticket nehmen fehlgeschlagen',
              type: 'error',
            })
            pendingTickets.splice(pendingButtonIndex, 1)
          })
      }
    }

    const onClickRow = (item, row) => {
      if (selectedRow.value) {
        selectedRow.value.select(false)
        selectedRow.value = null
      }

      if (item === selectedItem.value) {
        selectedItem.value = null
      } else {
        selectedItem.value = item
        selectedRow.value = row
        selectedRow.value.select()
      }
    }

    const onInput = value => {
      if (!value) {
        selectedItem.value = null
      }
      if (selectedRow.value) {
        selectedRow.value.select(false)
        selectedRow.value = null
      }
    }

    onBeforeUnmount(_ => clearTickets())

    return {
      page,
      search,
      pendingTickets,
      headers,
      tickets,
      pagination,
      pending,
      highlightClasses,
      selectedItem,
      onClickRow,
      onTakeTicket,
      onInput,
    }
  },
})
</script>


<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}
</style>
